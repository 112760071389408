.dzu-dropzone {
  overflow: hidden;
}

.card-header {
  background-color: #044470;
  color: white ;
  border-radius: 0 !important;
}

.modal-header {
  background-color: #044470;
  border-radius: 0 !important;
}

.modal-header h5 {
  color: white !important;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.modal-content {
  border-radius: 0;
}

.card-header h6 {
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

label {
  color: #044470;
  font-weight: bold;
}

.btn-primary {
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
}

.btn-danger {
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
}

.card {
  border-radius: 0;
}

.bg-light {
  /*background-color: #ccc !important;*/
  color: #044470 !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: white;
  background-color: #cb660a;
  border-color: #e5760a; /*set the color you want here*/
  box-shadow: 0 5px 15px rgba(0,0,0,.05), 0 4px 10px rgb(227, 227, 227);
}

.ng-blue {
  color: #044470 !important;
  font-weight: bold;
  vertical-align: center !important;
}

.simulation-results tbody tr td:first-child {
  color: grey;
  font-weight: bold;
}

.simulation-data tbody tr td:first-child {
  color: #044470 !important;
  font-weight: bold;
  vertical-align: center;
}

.main-sidebar .nav-item {
  background-color: #044470;
  color: white !important;
}

.main-sidebar .nav-item a {
  color: white !important;
}

/*.main-sidebar .nav .nav-item:hover {*/
/*  background-color: #043560 !important;*/
/*}*/

.main-sidebar .nav .nav-item .nav-link.active, .main-sidebar .nav .nav-item .nav-link:hover, .main-sidebar .nav .nav-item.active, .main-sidebar .nav .nav-item:hover {
  box-shadow: inset 0.1875rem 0 0 #cb660a;
  color: #043358 !important;
  background-color: white;
}

.main-sidebar .nav .nav-item .nav-link.active i, .main-sidebar .nav .nav-item .nav-link:hover i, .main-sidebar .nav .nav-item.active i, .main-sidebar .nav .nav-item:hover i {
  color: #cb660a;
}

.nav-item .dropdown a {
  margin-top: 20px;
}

.add-elements {
  color: #e5760a;
  font-size: 25px !important;
  /*text-align: right;*/
  float: right;
  cursor: pointer;
}

input[disabled] {
  background-color:transparent !important;
  border: 0;
  font-size: 1em;
  cursor: default !important;
  color: black;
}

textarea[disabled] {
  background-color:transparent !important;
  border: 0;
  font-size: 1em;
  cursor: default !important;
  color: black;
}

select[disabled] {
  background-color:transparent !important;
  border: 0;
  font-size: 1em;
  cursor: default !important;
  background-size: 0px;
  color: #495057 !important;
}

/*.main-content-container {*/
/*  background-image: url("images/fondo_parque_industrial.jpg") !important;*/
/*  background-repeat: no-repeat;*/
/*  background-position: center, center;*/
/*  background-size: 80%;*/
/*}*/

/*.card-header {*/
/*  background-image: url("images/blue-with-vignette-marble-texture-background-with-copy-space_23-2148327728.jpg") !important;*/
/*}*/

/*.card {*/
/*  background: rgba(240, 240, 240, 0.9);*/
/*}*/

.tl-success {
  color: #00bf00;
  text-align: center;
}

.tl-warning {
  color: #bfbb15;
  text-align: center;
}

.tl-danger {
  color: #bf000a;
  text-align: center;
}

.dzu-dropzone {
  overflow: hidden !important;
}

.np {
  padding: 0;
}

.modal-body {
  /*max-height: 350px;*/
  padding: 15px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 767px) {
  .modal-body {
    max-height: 500px;
    padding: 15px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal {
    left: 1%;
  }
}

@media (min-width: 768px) {
  .modal {
    left: 5%
  }
}

.home-slide {
  background-color: #044470;
  /*background-color: #0479c6;*/
  color:white;
  font-weight: bold;
}

.home-slide img {
  width: 26%;
  clear: both;
}

.f-right {
  float: right;
}

.card-header {
  border-bottom: 4px solid #E57200 !important;
}

.modal-header {
  display: block;
  border-bottom: 4px solid #E57200 !important;
}

.user-menu{
  margin-top: 5px;
}

.header-icon {
  float: left;
  font-size: 25px !important;
}

.logo-side {
  width: 80%;
  margin-left: 10px;
}

.input-group-append {
  /*background-color: #e5760a;*/
  /*color: white;*/
  /*background-color: red !important;*/
  /*display:none !important;*/
}

.btn-primary:disabled,
.btn-primary[disabled]{
  /*border: 1px solid #999999;*/
  /*background-color: #cccccc;*/
  /*color: #666666;*/
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  opacity: 0.8;
  box-shadow: 0 0 0 0 !important;
}

.btn-primary:active,
.btn-primary[active]{
  /*border: 1px solid #999999;*/
  /*background-color: #cccccc;*/
  /*color: #666666;*/
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  opacity: 0.8;
  box-shadow: 0 0 0 0 !important;
}

.controlRow__root {
  margin-bottom: 5px;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  opacity: 0.8;
  box-shadow: 0 0 0 0 !important;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  opacity: 0.8;
  box-shadow: 0 0 0 0 !important;
}

table-datatable__root thead {
  color: red !important;
}

.thead {
  color: #044470 !important;
  background-color: #fbfbfb!important;
  font-weight: bold;
}

.top-em {
  border-top: 2px solid #898d91;
}

.note {
  font-weight: bolder;
  margin-bottom: 10px;
  font-size: 16px;
}

pre {
  font-family: inherit;
}

.table-no-top td{
  /*color: blue;*/
  /*border-top: 0px solid #dee2e6 !important;*/
  /*border: none !important;*/
  border: none;
}

.incomplete {
  color: #e5760a;
  font-weight: bolder;
}