@charset "UTF-8";

@font-face {
  font-family: "untitled-font-1";
  src:url("fonts/untitled-font-1.eot");
  src:url("fonts/untitled-font-1.eot?#iefix") format("embedded-opentype"),
    url("fonts/untitled-font-1.woff") format("woff"),
    url("fonts/untitled-font-1.ttf") format("truetype"),
    url("fonts/untitled-font-1.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "untitled-font-1" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-1" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-altacliente:before {
  content: "\61";
}
.icon-anuncios:before {
  content: "\62";
}
.icon-avisos:before {
  content: "\63";
}
.icon-cliente:before {
  content: "\64";
}
.icon-clienteactual:before {
  content: "\65";
}
.icon-corporativos:before {
  content: "\66";
}
.icon-contractuales:before {
  content: "\67";
}
.icon-home:before {
  content: "\68";
}
.icon-materialesrecurrentes:before {
  content: "\69";
}
.icon-operativos:before {
  content: "\6a";
}
.icon-regulatorios:before {
  content: "\6b";
}
.icon-repositorio-personal:before {
  content: "\6c";
}
.icon-sharedfile:before {
  content: "\6d";
}

.ng-icon {
font-size: 20px !important;
}